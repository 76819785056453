
// document.addEventListener("DOMContentLoaded", function () {



//   /* FORM */

//   initForm();

//   function initForm() {

//       let formSubmitBtn = document.querySelector('.js-contact-form button[type="submit"]');
     
//       formSubmitBtn.addEventListener('click', (e) => {
//           e.preventDefault();
//           sendFormData();
//       });


//   }

//   function sendFormData() {
//       const form = document.querySelector('.js-contact-form form');
//       let formData = new FormData(form);
//       formData.append('page-url', window.location.href);
//       const request = new XMLHttpRequest();
//       request.open("POST", "http://localhost/website-2020/assets/backend/mailer.php");
//       console.log("test");
//       request.send(formData);

//       request.onload = () => {
//           if (request.status != 200) {
//               document.querySelector('.js-message').classList.add('is-shown');
//           } else {
//               document.querySelector('.js-message').classList.add('is-shown');
//               setTimeout(() => {
//                   document.querySelector('.js-message').classList.remove('is-shown');
//               }, 4500);
//           }
//       }
//   }
// });