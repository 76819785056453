window.onload = function () {
  window.cookieHandler = initCookieBar();
  setupAnimation();
  setupAnimationSection();
  
  window.addEventListener("resize", onResize);
  window.dispatchEvent(new Event("resize"));
  lazyVideoLoading();

  
  // function setActiveLink(currentUrl) {
  //   var activeClass = "active";
  //   var navLinks = document.querySelectorAll("#navbar-list a");
  //   for (var i = 0, l = navLinks.length; i < l; i++) {
  //     var link = navLinks[i];
  //     var url = link.getAttribute("href");
  //     if (currentUrl == url) {
  //       link.classList.add(activeClass);
  //     } else {
  //       link.classList.remove(activeClass);
  //     }
  //   }
  // }

  // function ready(fn) {
  //   if (document.readyState != "loading") {
  //     fn();
  //   } else {
  //     document.addEventListener("DOMContentLoaded", fn);
  //   }
  // }

  // function runApplication() {
  //   setActiveLink(window.location.pathname + window.location.hash);
  // }
  // ready(runApplication);

  //klickbare images (nicetohave) - funktioniert noch nicht
  // document.getElementById('image-events').addEventListener('click', changeClicks);
  // function changeClicks (event){
  //   let target = event.target;
  //   let figures = document.querySelectorAll('figure');
  //   figures.forEach(element => {
  //     this.classList.remove('photostack-current');
  //     // this.style.cssText = "";
  //   });
  //   console.log("figures:", figures);
  //   let parent = target.closest("#parent");
  //    console.log("target", target);
  //    console.log("parent", parent);

  //   //target.style.border = "thick solid #0000FF";
  //   parent.classList.add('photostack-current');
  //   // parent.style.cssText('');
  //   parent.style.cssText = "transform: translate(420px, 170px) rotate(0deg)";
  //   console.log("hallß");

  // }
  
};
let ticking = false;
let cta = [];
let chatOpened = false; // Flag variable to track if chat has been opened

function openChat() {
  if (typeof $crisp !== 'undefined') {
    if (!chatOpened) { // Check if chat has not been opened before
      $crisp.push(['do', 'chat:show']);
      $crisp.push(['do', 'chat:open']);
      document.querySelector('.js-floating-cta').classList.add('hide');
      chatOpened = true; // Set chatOpened flag to true
      
      $crisp.push(["on", "chat:closed", hideCrispChatBtn]);
      $crisp.push(["do", "message:show", ["picker", {
        "id": "career",
        "text": "Welches Thema ist Deins?",
        "choices": [
          {
            "value": "job",
            "label": "Dein neuer Job",
            "selected": false
          },
          {
            "value": "project",
            "label": "Dein neues Projekt",
            "selected": false
          },
          {
            "value": "general",
            "label": "Was Allgemeines",
            "selected": false
          }
        ]
      }]]);
      // Variable to track if the answer message has been sent
      let answerSent = false;

      $crisp.push(["on", "message:received", function(data) {
        // Skip responses not being updates
        if (data.origin !== "update") {
          return;
        }
        
        // Skip messages types other than pickers
        if (data.type !== "picker") {
          return;
        }
        
        // Skip other ids
        if (data.content.id !== "career") {
          return;
        }
        
        let _choice = data.content.choices.find(choice => choice.selected);
        
        if (_choice.label === "Dein neuer Job") {
          if (!answerSent) {
            $crisp.push(["do", "message:show", ["text", "Super. Für welche Richtung interessierst Du Dich?"]]);
            answerSent = true;
          }
        } else if (_choice.label === "Dein neues Projekt") {
          if (!answerSent) {
            $crisp.push(["do", "message:show", ["text", "Super. Welche Idee schwebt Dir vor?"]]);
            answerSent = true;
          }
        } else if (_choice.label === "Was Allgemeines") {
          if (!answerSent) {
            $crisp.push(["do", "message:show", ["text", "Okay. Worüber wollen wir reden?"]]);
            answerSent = true;
          }
        }
      }]);
    }
  } else {
    scrollToContactForm();
  }
}

function scrollToContactForm() {
  const contactForm = document.querySelector('.contact');
  if (contactForm) {
    contactForm.scrollIntoView({ behavior: 'smooth' });
  }
}

window.CRISP_READY_TRIGGER = function () {
  let floatingChatBtn = document.querySelector('.js-button-chat');
  let floatingContactBtn = document.querySelector('.js-button-contact');
  
  // Hide chat button if $crisp is not defined
  if (typeof $crisp === 'undefined') {
    floatingChatBtn.classList.add('hide');
    floatingContactBtn.classList.remove('hide');
    floatingContactBtn.addEventListener('click', scrollToContactForm);
  } else {
    // Show chat button if $crisp is defined
    floatingChatBtn.classList.remove('hide');
    floatingContactBtn.classList.add('hide');
    floatingChatBtn.addEventListener('click', openChat);
  }
};

function hideCrispChatBtn() {
  $crisp.push(["do", "session:reset"]);
  $crisp.push(["do", "message:removeall"]);
  $crisp.push(['do', 'chat:hide']);
  document.querySelector('.js-floating-cta').classList.remove('hide');
  chatOpened = false; // Reset chatOpened flag to false
}


window.addEventListener('beforeunload', function() {
  hideCrispChatBtn();
});
function hideFloatingContactBtn() {
  let floatingContactBtn = document.querySelector('.js-button-contact')
  if (floatingContactBtn) {
      floatingContactBtn.style.display = 'none';
  }
}
/* FLOATING TRIGGER */

function isInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
        rect.top >= -(rect.height/10) &&
        rect.left >= 0 &&
        (rect.bottom - (rect.height/1.25) ) <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)

    );
}


function onResize() {
  // console.log(window.innerWidth + " " + window.innerHeight);
  // let animatedSections = calculateSectionBorders(); // also called in setupAnimationSection
  // console.dir(animatedSections);
  // toggleActiveSections(animatedSections);  // also called in setupAnimationSection
  // re-calculateSectionBorders
  setupAnimationSection();
}

function toCamelCase(str) {
  return str.replace(/-([a-z])/g, function (g) {
    return g[1].toUpperCase();
  });
}

function setupAnimation() {
  if (lax) {
    lax.init();
    lax.addDriver("scrollY", function () {
      return window.scrollY;
    });

    document.querySelectorAll(".lax").forEach((item) => {
      let transitions = {};
      let animationValueMap;
      item.getAttributeNames().forEach((attr, index) => {
        if (attr.indexOf("data-lax") === 0) {
          const type = toCamelCase(attr.split("data-lax-")[1]);
          const animation = item.getAttribute(attr).split("|");
          
          const driverValueMap = animation[0].split(",");

          // console.dir(driverValueMap);

          if (animation.length > 1) {
            animationValueMap = animation[1].split(",");
          }
          if (driverValueMap.length !== animationValueMap.length) {
            // console.log(item);
          }
          transitions[type] = [driverValueMap, animationValueMap];
        }
      });

      lax.addElement(item, {
        scrollY: transitions,
      });
    });
  }
}

function setupAnimationSection() {
  let animatedSections = calculateSectionBorders();
  toggleActiveSections(animatedSections);
  document.addEventListener("scroll", (event) =>
    window.requestAnimationFrame(() => toggleActiveSections(animatedSections))
  );
  // console.log("animatedSections"+ animatedSections);
}

function calculateSectionBorders() {
  let intro = document.querySelector(".js-intro-section");
  let sections = document.querySelectorAll(".js-animation-section");
  let animatedSections = [];
  let animatedSectionsHeight = 0;
  let animationContainer = document.querySelector(".js-scroll-animation");
  let top = 0;
  let count = 0;

  // console.log(intro.getBoundingClientRect().height);

  sections.forEach((item, index) => {
    if (index === 0) {
      top = animationContainer.getBoundingClientRect().top + window.scrollY;
      // top += item.getBoundingClientRect().height;
    } else {
      top = animatedSections[index - 1].bottom;
    }
    animatedSections.push({
      element: item,
      top: top,
      // bottom: top + window.innerHeight,
      bottom: top + item.getBoundingClientRect().height,
    });
    animatedSectionsHeight += item.getBoundingClientRect().height;

    // console.log(index+1 + "———————————");
    // console.log(item.getBoundingClientRect().height);
    count = index + 1;
  });

  // animationContainer.style.height = animationContainerHeight;
  // console.log("flow: " + animationContainerHeight);
  // console.log("JS  : " + animatedSectionsHeight);
  // console.log(count + "vh : " + window.innerHeight * count);
  // console.log("scaleFactor: " + animationContainerHeight / (window.innerHeight * (count+1) ));

  let bottom = animatedSections.length > 0 ? animatedSections[0].top : 0;
  animatedSections.unshift({
    element: intro,
    top: 0,
    bottom: bottom,
  });

  // console.dir(animatedSections);
  return animatedSections;
}

/**
 *
 * @param {Array} animatedSections - Array of sections where all sections that intersect the viewport are not hidden
 * @param {Number} minIntersectPart - min relative area of section that should intersect the viewport
 */
function toggleActiveSections(animatedSections, minIntersectPart = 0) {
  const activeSections = animatedSections.filter((section) => {
    section.height = section.bottom - section.top;
    intersectsViewport =
      section.bottom - minIntersectPart * section.height > window.scrollY &&
      section.top + minIntersectPart * section.height <
        window.scrollY + window.innerHeight;
    return intersectsViewport;
  });

  // animatedSections.forEach((section) => {
  //   section.element.classList.add("hidden");
  //   section.element.style.backgroundColor = "rgba(255, 0, 0, 0.2)"; // color indicator for active sections
  // });

  activeSections.forEach((section) => {
    section.element.classList.remove("hidden");
    // section.element.style.backgroundColor = "rgba(0, 255, 0, 0.2)"; // color indicator for active sections
  });
}

function lazyVideoLoading() {
  let lazyVideos = [].slice.call(document.querySelectorAll("video.lazy"));
  if ("IntersectionObserver" in window) {
    let lazyVideoObserver = new IntersectionObserver(function (
      entries,
      observer
    ) {
      entries.forEach(function (video) {
        if (video.isIntersecting) {
          for (let source in video.target.children) {
            let videoSource = video.target.children[source];
            if (
              typeof videoSource.tagName === "string" &&
              videoSource.tagName === "SOURCE"
            ) {
              videoSource.src = videoSource.dataset.src;
            }
          }

          video.target.load();
          video.target.classList.remove("lazy");
          lazyVideoObserver.unobserve(video.target);
        }
      });
    });

    lazyVideos.forEach(function (lazyVideo) {
      lazyVideoObserver.observe(lazyVideo);
    });
  }
}



let updater;

document.addEventListener('DOMContentLoaded', updateCounter);
document.addEventListener('resize', updateCounter);
document.addEventListener('scroll', updateCounter);

function isElementInViewport(el) {
    let rect = el.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

function updateCounter() {
    let counters = document.querySelectorAll('.counter__value');
    let interval = 10;

    if (counters.length > 0) {
        if (isElementInViewport(counters[0])) {
            let min = parseInt(counters[0].dataset.min);
            let current = parseInt(counters[0].dataset.current);

            if (current <= min) {
                updater = setInterval(update, interval);
            }
        }
    }

    function update() {
        let finished = true;
        counters.forEach(function (counter) {
            let min = parseInt(counter.dataset.min);
            let max = parseInt(counter.dataset.max);
            let current = parseInt(counter.dataset.current);
            let speed = parseInt(counter.dataset.speed);

            let change;

            if (current < max) {
                change = Math.ceil((max - min) / (speed / interval));
                counter.dataset.current = current + change;
                finished = false;
            } else {
                next = max;
            }
            counter.innerHTML = (counter.dataset.current > max) ? max : counter.dataset.current
        });

        if (finished) {
            clearInterval(updater);
        }
    }
}


